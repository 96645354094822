var CACHE_NAME = "petspot-cache_i1";
var urlsToCache = ["./"];
var useCache = false; // in dev mode, do not use cache

self.addEventListener("install", function (event) {
  // Perform install steps
  event.waitUntil(
    caches.open(CACHE_NAME).then(function (cache) {
      console.log("This site has been loaded from service-worker.js cache.");
      return cache.addAll(urlsToCache);
    })
  );
});

self.addEventListener("fetch", function (event) {
  event.respondWith(
    caches.match(event.request).then(function (response) {
      // Cache hit - return response
      if (useCache && response) {
        return response;
      }

      return fetch(event.request).then(function (response) {
        // Check if we received a valid response
        if (!response || response.status !== 200 || response.type !== "basic") {
          return response;
        }

        // IMPORTANT: Clone the response. A response is a stream
        // and because we want the browser to consume the response
        // as well as the cache consuming the response, we need
        // to clone it so we have two streams.
        var responseToCache = response.clone();

        caches.open(CACHE_NAME).then(function (cache) {
          const requestFromExtension =
            responseToCache.url.indexOf("chrome-extension") !== -1;
          if (requestFromExtension) return;
          cache.put(event.request, responseToCache);
        });

        return response;
      });
    })
  );
});
